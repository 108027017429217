import { makeStyles } from "@material-ui/core/styles";

import db, { firestore } from "../../firebase/db";

export type DanceType =
  | "soloDance"
  | "discoBoogie1"
  | "discoBoogie2"
  | "ballroom1"
  | "ballroom2"
  | "ballroom3"
  | "ballroom4";

export const DANCES: Record<DanceType, string> = {
  soloDance: "18u00 Solo Dans",
  ballroom4: "19u00 Stijldansen gevorderden",
  discoBoogie1: "20u00 Disco-Boogie",
  discoBoogie2: "21u00 Disco-Boogie (startdatum: 13 oktober)",
  ballroom3: "18u00 Stijldansen 3",
  ballroom2: "20u00 Stijldansen 2",
  ballroom1: "19u00 Stijldansen – Start to dance (beginners)",
};

export const DANCE_LIST_1: DanceType[] = [
  "soloDance",
  "ballroom4",
  "discoBoogie1",
];
export const DANCE_LIST_2: DanceType[] = [
  "ballroom3",
  "ballroom1",
  "ballroom2",
  "discoBoogie2",
];

export interface DancerInformation {
  firstName: string;
  lastName: string;
  email: string;
  address: {
    street: string;
    number: string;
    zip: string;
    city: string;
  };
  phoneNumber: string;
  subscribeNewsletter: boolean;
}

export interface MainFormValue {
  dancer1: DancerInformation;
  dancer2: DancerInformation;
  hasPartner: boolean;
  useSameContact: boolean;
  dances: Record<DanceType, boolean>;
  message: string;
  createdAt: Date;
}

const DEFAULT_DANCER_INFORMATION: DancerInformation = {
  firstName: "",
  lastName: "",
  email: "",
  address: {
    street: "",
    number: "",
    zip: "",
    city: "",
  },
  phoneNumber: "",
  subscribeNewsletter: true,
};
export const DEFAULT_FORM_VALUES = {
  dancer1: DEFAULT_DANCER_INFORMATION,
  dancer2: DEFAULT_DANCER_INFORMATION,
  hasPartner: true,
  useSameContact: false,
  dances: {
    soloDance: false,
    ballroom1: false,
    ballroom2: false,
    ballroom3: false,
    ballroom4: false,
    discoBoogie1: false,
    discoBoogie2: false,
  },
  message: "",
  createdAt: new Date(),
};

export const SPACING = 2;
export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: 0,
    marginBottom: 5,
  },
  gridItem: {
    marginTop: (-1 * theme.spacing(SPACING)) / 2,
    marginBottom: (-1 * theme.spacing(SPACING)) / 2,
  },
  separator: {
    height: "1px",
    background: "rgba(0, 0, 0, 0.23)",
    width: "100%",
  },
}));

export const formatFormValues = (values: MainFormValue) => values;

export const saveFormValues = async (values: MainFormValue): Promise<void> => {
  if (db === null) {
    return;
  }

  await db.collection("registrations").add({
    type: "cursus-registration-september-2024",
    dancer1: values.dancer1,
    dancer2: values.hasPartner
      ? {
          ...values.dancer2,
          address: values.useSameContact ? null : values.dancer2.address,
        }
      : null,
    message: values.message,
    dances: values.dances,
    createdAt: firestore.Timestamp.fromDate(values.createdAt),
    rejectedAt: null,
  });
};
